<template>
  <div class="pageContol listWrap templateList templateList1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">直播管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">直播课程</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="直播课程名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem"
                >直播课程名称:</span
              >
              <el-input
                size="small"
                v-model="liveCourseName"
                type="text"
                placeholder="请输入直播课程名称"
                clearable
              />
            </div>
            <div title="审核状态" class="searchboxItem ci-full">
              <span class="itemLabel">审核状态:</span>
              <el-select
                v-model="liveState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in liveStateList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              round
              @click="createdPeriod('add')"
              >创建直播课程</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="直播课程名称"
                align="left"
                show-overflow-tooltip
                prop="liveCourseName"
                min-width="260"
                fixed
              />
              <el-table-column
                label="直播课节数量"
                align="right"
                show-overflow-tooltip
                prop="lessonNum"
                min-width="100"
              />
              <el-table-column
                label="关联班级"
                align="left"
                show-overflow-tooltip
                prop="liveProjectName"
                min-width="180"
              />
              <el-table-column
                label="开始时间"
                align="left"
                show-overflow-tooltip
                prop="startTime"
                min-width="140"
              >
                <template slot-scope="scope">
                  {{ scope.row.startTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="结束时间"
                align="left"
                show-overflow-tooltip
                prop="endTime"
                min-width="140"
              >
                <template slot-scope="scope">
                  {{ scope.row.endTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="审核状态"
                align="left"
                show-overflow-tooltip
                prop="auditState"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.auditState == 40">                   
                    <el-tooltip
                      effect="dark"
                      :content="scope.row.auditReason"
                      placement="top-start"
                    >
                      <span
                        :class="scope.row.auditState == 40 ? 'tColor1' : ''"
                        >{{
                          $setDictionary(
                            "LIVECOURSEAUDITSTATE",
                            scope.row.auditState
                          )
                        }}<i class="el-icon-question" style="color:red;font-size: 14px;"></i></span
                      >
                    </el-tooltip>                   
                  </template>
                  <template v-else>
                    <span>{{
                      $setDictionary(
                        "LIVECOURSEAUDITSTATE",
                        scope.row.auditState
                      )
                    }}</span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                min-width="140"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="300px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="createdPeriod('edit', scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    style="padding: 0 5px"
                    v-if="scope.row.auditState !== '30'"
                    type="text"
                    size="mini"
                    @click="commitAudit(scope.row)"
                  >
                    {{ scope.row.auditState === "20" ? "撤销" : "提交" }}审核
                  </el-button>
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="seeFestival(scope.row)"
                    >直播课节</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="copyliveCourse(scope.row.liveCourseId)"
                    >复制</el-button
                  >
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="deleteliveCourse(scope.row.liveCourseId)"
                    >删除</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="55%"
      top="3%"
      :center="true"
      :before-close="doClose"
      :close-on-click-modal="false"
    >
      <div class="ovy-a">
        <el-form label-width="120px" ref="Form" :model="Form" :rules="rules">
          <el-form-item label="直播课程名称" prop="liveCourseName">
            <el-input
              v-model="Form.liveCourseName"
              type="text"
              size="small"
              maxlength="30"
              show-word-limit
              placeholder="请输入直播课程名称"
              :disabled="prohibit"
            />
          </el-form-item>
          <el-form-item label="直播课程封面" prop="thumbnail">
            <el-upload
              :on-change="handleAvatarSuccess2"
              :before-upload="beforeAvatarUpload2"
              :http-request="$requestMine"
              :show-file-list="false"
              class="img-el-upload"
              action
              accept="image/png, image/gif, image/jpg, image/jpeg"
              :disabled="prohibit"
            >
              <el-image
                :src="Form.logoUrl || require('@/assets/develop.png')"
                fit="contain"
                class="imgCenter"
              ></el-image>
            </el-upload>
            <p style="color: #f46173">
              请上传640*375（长*宽）像素的PNG、JPG格式的图片，且文件大小不超过1MB！
            </p>
          </el-form-item>
          <el-form-item label="直播课程简介" prop="courseProfile">
            <el-input
              v-model="Form.courseProfile"
              type="textarea"
              size="small"
              maxlength="500"
              show-word-limit
              placeholder="请输入直播课程简介"
              :disabled="prohibit"
            />
          </el-form-item>
        </el-form>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button class="bgc-bv" @click="doClose('Form')">取消</el-button>
        <el-button class="bgc-bv" @click="getSure('Form')" :disabled="prohibit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "liveCourse",
  components: {
    Empty,
    PageNum,
  },
  data() {
    return {
      liveCourseName: "", //直播课程名称
      liveStateList: [], //审核状态
      liveState: "",
      dialogVisible: false, //添加/编辑 - 直播课程-loading
      dialogTitle: "添加直播课程",
      Form: {
        liveCourseName: "", // 直播课程名称
        logoUrl: "", // 直播课程封面
        courseProfile: "", // 直播课程简介
      },
      // 添加/编辑 - 直播课程：数据校验
      rules: {
        liveCourseName: [
          { required: true, message: "请输入直播课程名称", trigger: "blur" },
          { max: 30, message: "直播课程名称小于30个字符长度", trigger: "blur" },
        ],
      },
      // 当审核状态为：20: "待审核"/30: "已通过" 时不可编辑；否则可编辑:10: "未申请"/40: "已驳回"
      prohibit: false
    };
  },
  mixins: [List],
  created() {
    this.getnoticeify();
  },
  methods: {
    // 获取审核状态下拉数据
    getnoticeify() {
      const list = this.$setDictionary("LIVECOURSEAUDITSTATE", "list") || [];
      for (let key in list) {
        this.liveStateList.push({ id: key, name: list[key] });
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.liveCourseName) {
        params.liveCourseName = this.liveCourseName;
      }
      if (this.liveState) {
        params.auditState = this.liveState;
      }
      this.doFetch(
        {
          url: "/biz/live/course/pageList",
          params,
          pageNum,
        },
        true,
        3
      );
    },
    /* 创建直播课程 */
    createdPeriod(stu, row) {
      this.dialogVisible = true;
      this.stu = stu;
      this.liveCourseId = row.liveCourseId;
      if (stu == "add") {
        this.dialogTitle = "添加直播课程";
      } else {
        this.dialogTitle = "修改直播课程";
        this.getInfo(row.liveCourseId);
        
        if(row.auditState == '20' || row.auditState == '30'){
          this.prohibit = true;
        } else {
          this.prohibit = false;
        }
      }
    },
    //编辑
    getInfo(liveCourseId) {
      this.$post("/biz/live/course/getInfo", { liveCourseId }, 3000, true, 3)
        .then((ret) => {
          this.Form = {
            ...ret.data,
          };
        })
        .catch((err) => {
          return;
        });
    },
    // 添加/编辑 - 点击取消
    doClose() {
      this.dialogVisible = false;
      this.$refs["Form"].resetFields();
      Object.keys(this.Form).forEach((key) => (this.Form[key] = ""));
    },
    // 添加/编辑 - 点击确定
    getSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.doeAjax();
        }
      });
    },
    // 添加/编辑 - 点击确定 - 方法
    doeAjax() {
      const parmar = {
        liveCourseName: this.Form.liveCourseName,
        logo: this.Form.logo,
        courseProfile: this.Form.courseProfile,
        logoHeight: this.Form.logoHeight,
        logoWidth: this.Form.logoWidth,
      };
      if (this.liveCourseId) {
        parmar.liveCourseId = this.liveCourseId;
      }
      this.$post(
        this.stu == "add"
          ? "/biz/live/course/insert"
          : "/biz/live/course/modify",
        parmar,
        3000,
        true,
        3
      )
        .then((ret) => {
          if (ret.status == 0) {
            this.$message({
              message: ret.message,
              type: "success",
            });
            this.dialogVisible = false;
            // this.doClose();
            this.getData(-1);
            this.reset();
          }
        })
        .catch((err) => {
          return;
        });
    },
    // 提/撤 审核
    commitAudit(row) {
      if (row.auditState === "20") {
        this.doDel(
          {
            title: "撤销审核",
            url: "/biz/live/course/undoCommitAudit",
            data: {
              liveCourseId: row.liveCourseId,
            },
            msg: "该直播课程已经提交审核，是否撤销审核？",
            ps: {
              type: "post",
              data: { liveCourseId: row.liveCourseId },
            },
          },
          true,
          3
        );
      } else {
        if (Number(row.lessonNum) > 0) {
          if (row.liveCourseName.length > 30) {
            this.$confirm(
              "您的课程名称已超过30个字,请修改课程名称后再提交审核。",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                closeOnClickModal: false,
              }
            )
              .then(() => {
                this.dialogTitle = "修改直播课程";
                this.dialogVisible = true;
                this.liveCourseId = row.liveCourseId;
                this.getInfo(row.liveCourseId);
              })
              .catch(() => {});
          } else {
            console.log(row.liveCourseId)
            this.doDel(
              {
                title: "提交审核",
                url: "/biz/live/course/commitAudit",
                data: {
                  liveCourseId: row.liveCourseId,
                },
                msg: "直播课程只有审核通过才可以使用，您是否提交直播课程审核？",
                ps: {
                  type: "post",
                  data: { liveCourseId: row.liveCourseId },
                },
              },
              true,
              3
            );
          }
        } else {
          this.$message.error("请先添加直播课节!");
        }
      }
    },
    /* 复制 */
    copyliveCourse(liveCourseId) {
      this.$post("/biz/live/course/copyCourse", { liveCourseId }, 3000, true, 3)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              type: "success",
              message: "复制成功",
            });
            this.getData();
          }
        })
        .catch((err) => {
          return;
        });
    },
    /* 删除 */
    deleteliveCourse(liveCourseId) {
      this.doDel(
        {
          url: "/biz/live/course/delete",
          msg: "你确定要删除该直播课程吗？",
          ps: {
            type: "post",
            data: { liveCourseId },
          },
        },
        true,
        3
      );
    },
    /* 直播课程封面 */
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.Form.logoUrl = result.data.fileURL;
            this.Form.logo = result.data.fileKey;
            console.log(this.Form.logoUrl);
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    beforeAvatarUpload2(file) {
      let that = this;
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          file.type
        ) === -1
      ) {
        that.$message.error("请上传正确的图片格式");
        return false;
      }
      var reader = new FileReader();
      reader.onload = function(event) {
        var txt = event.target.result;
        var img = document.createElement("img");
        img.src = txt;
        img.onload = function() {
          that.Form.logoHeight = img.width;
          that.Form.logoWidth = img.height;
        };
      };
      reader.readAsDataURL(file);
      return isLt2M;
    },
    /* 查看班级 */
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=  40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    /* 直播课节 */
    seeFestival(row) {
      this.$router.push({
        path: "/web/liveBroadcastManagement/liveClassFestival",
        query: {
          liveCourseId: row.liveCourseId,
          auditState: row.auditState,
        },
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (this.$route.params.refresh == true) {
          this.getData();
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="less">
.el-textarea {
  height: 13rem;
  .el-textarea__inner {
    height: 100%;
    resize: none;
  }
}
// .templateList1 {
//   // .el-cascader {
//   //   height: 32px !important;
//   // }
// }
.tColor1:hover {
  color: #409eff;
}
</style>